<template>
  <div>
     <nav class="navbar">
      <div class="navbar-wrapper">
        <ButtonIcon @click.native="goToPreviousPage" />
        <ProgressIndicator :currentPage="currentStep" :totalPages="totalPages" />
      </div>
    </nav>
    <div class="form-page" v-if="isMissingParams">
      <EmptyState
        icon-color="red"
        icon-class="bx bxs-error"
        :use-link="true"
        btn-text="Go to destinations"
        link-path="/destinations"
        title="Something went wrong"
        text="Please try later again."
      />
    </div>
    <form action="" class="form" @submit.prevent="submit">
      <div class="form-page">
        <h1 class="form-page-title">{{ $t("trip_request.form_1.title") }}</h1>
        <SelectDropdown ref="groupType" v-model="form.groupType"
          :description="$t('trip_request.form_1.input_2.placeholder')" :disabled="loading" :errorMsg="groupTypeErrMsg"
          :showErrMsg="$v.form.groupType.$error" :label="$t('trip_request.form_1.input_2.label')" :optionsList="groupTypes"
          :show-label="true" class="w-full mb-6" @blur="$v.form.groupType.$touch()"
          @change.native="changeGroupType()" />

        <NumberInputSpinner 
          :label="$t('trip_request.form_1.input_3.label_2')" 
          :value="form.numOfAdults" 
          class="w-full"
          name="adults" 
          @blur="$v.form.numOfAdults.$touch()" 
          @getValue="setAmountOfAdults" 
          @keyup="setAmountOfAdults" 
        />
        <ErrorMessage class="mt-2 mb-4" v-if="!$v.form.numOfAdults.minValue && $v.form.numOfAdults.$error"
          :errorMsg="$t('trip_request.form_1.input_3.error')" />
        <Divider />
        <NumberInputSpinner v-if="showChildrenInputSpinner" :label="$t('trip_request.form_1.input_3.label_3')"
          :value="form.numOfChildren" class="w-full" name="children" @getValue="setAmountOfChildren"
          @keyup="setAmountOfChildren" />
        <Divider v-if="showChildrenInputSpinner" />

        <p v-if="form.numOfChildren > 0" class="mt-12 label">
          {{ childrenText }}
        </p>

        <Banner v-if="form.numOfChildren > 0" type="warning" :description="`${$t(
          'trip_request.form_1.input_4.banner.description'
        )}`" :title="`${$t('trip_request.form_1.input_4.banner.title')}`" class="w-full mb-6" />

        <div v-for="(item, index) in form.childrenAgeDropdowns" :key="index"
          :set="(v = $v.form.childrenAgeDropdowns.$each[index])" class="w-full mb-6">
          <label :for="`child-${index + 1}-age`">
            <span class="label-child">
              {{ $t("trip_request.form_1.input_4.label_3") }} {{ index + 1 }}
            </span>
            <select :id="`child-${index + 1}-age`" :ref="`child-${index + 1}-age`" v-model="item.age"
              :class="[v.age.$error ? 'border-red-base' : 'border-grey-dark']" :name="`child-${index + 1}-age`"
              class="select-field" @blur="$v.form.childrenAgeDropdowns.$touch()" @change="changeChildAge(index + 1)">
              <option disabled="disabled" selected="selected" v-text="$t('trip_request.form_1.input_3.label_4')" />
              <option v-for="(option, index) in childrenAges" :key="index" :value="option.value">
                {{ option.name }}
              </option>
            </select>
          </label>
          <ErrorMessage class="mt-2" v-if="v.age.$error && !v.age.required"
            :errorMsg="$t('trip_request.form_1.input_4.error')" />
        </div>
        <ButtonPrimary :text="$t('trip_request.button')" class="desktop-cta" />
      </div>

      <div class="bottom-nav">
        <div class="bottom-nav-wrapper">
          <ButtonPrimary :text="`${$t('trip_request.button')}`" class="block h-12 px-8 w-full" />
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import Banner from "@/components/banners/Banner";
import ButtonIcon from "@/components/buttons/ButtonIcon";
import SelectDropdown from "@/components/dropdowns/SelectDropdown";
import NumberInputSpinner from "@/components/inputs/NumberInputSpinner";
import { mapActions, mapGetters } from "vuex";
import Divider from "@/components/dividers/Divider";
import ErrorMessage from "@/components/ErrorMessage";
import EmptyState from "@/components/EmptyState";

const {
  required,
  numeric,
  minValue,
  // requiredIf,
} = require("vuelidate/lib/validators");

export default {
  name: "traveler-details",
  components: {
    Divider,
    Banner,
    ButtonIcon,
    SelectDropdown,
    NumberInputSpinner,
    ErrorMessage,
    EmptyState
  },
  data() {
    return {
      isMissingParams: undefined,
      form: {
        childrenAgeDropdowns: [],
        numOfChildren: 0,
        numOfAdults: 1,
        groupType: null,
        budgetCurrency: null,
        budgetValue: null,
      },
      loading: false,
      childrenAges: [
        {
          name: `17 ${this.$tc("trip_request.form_1.input_2.years", 17)}`,
          value: "17",
        },
        {
          name: `16 ${this.$tc("trip_request.form_1.input_2.years", 16)}`,
          value: "16",
        },
        {
          name: `15 ${this.$tc("trip_request.form_1.input_2.years", 15)}`,
          value: "15",
        },
        {
          name: `14 ${this.$tc("trip_request.form_1.input_2.years", 14)}`,
          value: "14",
        },
        {
          name: `13 ${this.$tc("trip_request.form_1.input_2.years", 13)}`,
          value: "13",
        },
        {
          name: `12 ${this.$tc("trip_request.form_1.input_2.years", 12)}`,
          value: "12",
        },
        {
          name: `11 ${this.$tc("trip_request.form_1.input_2.years", 11)}`,
          value: "11",
        },
        {
          name: `10 ${this.$tc("trip_request.form_1.input_2.years", 10)}`,
          value: "10",
        },
        {
          name: `9 ${this.$tc("trip_request.form_1.input_2.years", 9)}`,
          value: "9",
        },
        {
          name: `8 ${this.$tc("trip_request.form_1.input_2.years", 8)}`,
          value: "8",
        },
        {
          name: `7 ${this.$tc("trip_request.form_1.input_2.years", 7)}`,
          value: "7",
        },
        {
          name: `6 ${this.$tc("trip_request.form_1.input_2.years", 6)}`,
          value: "6",
        },
        {
          name: `5 ${this.$tc("trip_request.form_1.input_2.years", 5)}`,
          value: "5",
        },
        {
          name: `4 ${this.$tc("trip_request.form_1.input_2.years", 4)}`,
          value: "4",
        },
        {
          name: `3 ${this.$tc("trip_request.form_1.input_2.years", 3)}`,
          value: "3",
        },
        {
          name: `2 ${this.$tc("trip_request.form_1.input_2.years", 2)}`,
          value: "2",
        },
        {
          name: `1 ${this.$tc("trip_request.form_1.input_2.years", 1)}`,
          value: "1",
        },
        {
          name: `0 ${this.$tc("trip_request.form_1.input_2.years", 0)}`,
          value: "0",
        },
      ],
      travelReasons: [
        {
          name: this.$t("trip_request.form_2.input_2.values.value_1"),
          value: this.$t("trip_request.form_2.input_2.values.value_1"), //bachelor party
        },
        {
          name: this.$t("trip_request.form_2.input_2.values.value_2"),
          value: this.$t("trip_request.form_2.input_2.values.value_2"), //birthday
        },
        {
          name: this.$t("trip_request.form_2.input_2.values.value_3"),
          value: this.$t("trip_request.form_2.input_2.values.value_3"), //honeymoon
        },
        {
          name: this.$t("trip_request.form_2.input_2.values.value_4"),
          value: this.$t("trip_request.form_2.input_2.values.value_4"), //retreat
        },
        {
          name: this.$t("trip_request.form_2.input_2.values.value_5"),
          value: this.$t("trip_request.form_2.input_2.values.value_5"), //school trip
        },
        {
          name: this.$t("trip_request.form_2.input_2.values.value_6"),
          value: this.$t("trip_request.form_2.input_2.values.value_6"), //vacation
        },
        {
          name: this.$t("trip_request.form_2.input_2.values.value_7"),
          value: this.$t("trip_request.form_2.input_2.values.value_7"), //other
        },
      ],
      groupTypes: [
        {
          name: this.$t("trip_request.form_1.input_2.values.value_1"),
          value: "Couple", //
        },
        {
          name: this.$t("trip_request.form_1.input_2.values.value_2"),
          value: "Family", //Family
        },
        {
          name: this.$t("trip_request.form_1.input_2.values.value_3"),
          value: "Friends", //Friends
        },
        {
          name: this.$t("trip_request.form_1.input_2.values.value_4"),
          value: "Organisation", //Organisation (Company, church, school, NGO, etc.
        },
        {
          name: this.$t("trip_request.form_1.input_2.values.value_5"),
          value: "Solo", // Solo
        },
        {
          name: this.$t("trip_request.form_1.input_2.values.value_6"),
          value: "Other", //Other
        },
      ],
    };
  },
  validations: {
    form: {
      groupType: {
        required,
      },
      numOfAdults: {
        required,
        numeric,
        minValue: minValue(1),
      },
      childrenAgeDropdowns: {
        $each: {
          age: { required },
        },
      },
    },
  },
  watch: {
    "form.numOfChildren"(newValue) {
      this.setNumOfChildren(newValue);
      if (newValue < 1) this.setChildrenAgeDropdown([]);
    },
    "form.numOfAdults"(newValue, oldValue) {
      let selectValue = this.$refs["groupType"].$el.children[1].value;
      let select = this.$refs["groupType"].$el.children[1];

      if (selectValue === "Solo" && oldValue === 1) {
        select.selectedIndex = 6;
        this.showChildren = true;
        this.setGroupType("Other");
      }

      if (selectValue === "Couple" && oldValue === 2) {
        select.selectedIndex = 6;
        this.showChildren = true;
        this.setGroupType("Other");
      }

      this.setNumOfAdults(newValue);
    },
  },
  computed: {
    ...mapGetters("request", ["travelerDetails", "step"]),
    ...mapGetters({
      childrenAgeDropdowns: "request/childrenAgeDropdowns",
      budget: "request/budget",
      budgetCurrency: "request/budgetCurrency",
      groupType: "request/groupType",
      numOfChildren: "request/numOfChildren",
      numOfAdults: "request/numOfAdults",
      isTravelersDetailsPage: "request/isTravelersDetailsPage",
      isExperience: "request/isExperience",
      totalPages: "request/totalPages",
      currentStep: "request/currentStep",
      formData: "request/form",
      showChildrenInputSpinner: "request/showChildrenInputSpinner",
    }),
    groupTypeErrMsg() {
      let msg;
      if (!this.$v.form.groupType.required) {
        msg = this.$t("trip_request.form_1.input_2.error");
      }
      return msg;
    },
    childrenText() {
      if (this.form.numOfChildren === 1) {
        return this.$t("trip_request.form_1.input_4.label_1");
      }
      return this.$t("trip_request.form_1.input_4.label_2");
    },
  },
  methods: {
    ...mapActions({
      increasePage: "request/increasePage",
      decreasePage: "request/decreasePage",
      setNumOfAdults: "request/setNumOfAdults",
      setNumOfChildren: "request/setNumOfChildren",
      setGroupType: "request/setGroupType",
      setIsExperiencesPage: "request/setIsExperiencesPage",
      toggleChildrenInputSpinner: "request/toggleChildrenInputSpinner",
      setChildrenAgeDropdown: "request/setChildrenAgeDropdown",
      setIsInterestPage: "request/setIsInterestPage",
      setFormData: "request/setFormData"
    }),
    validateURLParams() {
      let q = this.$route.query.q;
      let country = this.$route.query.country;
      if (q === undefined || country === undefined) {
        this.isMissingParams = true;
      } else {
        this.isMissingParams = false;
      }
    },
    changeChildAge(index) {
      let select = this.getChildAgeSelect(index);
      let currentAge = select.options[select.selectedIndex].value; //Get current select dropdown value
      this.setAgeOfChildren(currentAge, index);
    },
    getChildAgeSelect(index) {
      let refs = this.$refs;
      let forValue = `child-${index}-age`;
      let select;

      //Get selected child age dropdown element
      for (let key in refs) {
        let item = refs[key];

        if (Array.isArray(item)) {
          if (item[0].id === forValue) select = item[0];
        }
      }

      return select;
    },
    setAgeOfChildren(age, index) {
      let array = [...this.childrenAgeDropdowns];
      let i = index - 1;
      // check if item id is equal to selected drop-down index value/
      let entry = array.filter((entry) => entry.id === index);
      // if array is empty/does not exist
      if (entry.length === 0) {
        array.push({ id: index, age: age });
      } else {
        //if it exists
        array[i].age = age;
      }

      array = array.sort((a, b) => {
        return a.id - b.id;
      });
      this.setChildrenAgeDropdown(array);
    },
    changeGroupType() {
      let select = this.$refs["groupType"].$el.children[1].children[0]; //Get group type select dropdown
      let currentGroupType = select.options[select.selectedIndex].value; //Get current select dropdown value
      this.setGroupType(currentGroupType);

      if (currentGroupType === "Couple") {
        this.form.numOfAdults = 2;
        this.setNumOfAdults(2);
        this.form.numOfChildren = 0;
        this.setNumOfChildren(0);
        this.setChildrenAgeDropdown([]);
        this.toggleChildrenInputSpinner(false);
        this.setAmountOfChildren(0);
      } else if (currentGroupType === "Solo") {
        this.form.numOfAdults = 1;
        this.setNumOfAdults(1);
        this.form.numOfChildren = 0;
        this.setNumOfChildren(0);
        this.setChildrenAgeDropdown([]);
        this.toggleChildrenInputSpinner(false);
        this.setAmountOfChildren(0);
      } else {
        this.toggleChildrenInputSpinner(true);
      }
    },
    setAmountOfChildren(value) {
      if (value < 0) {
        this.setNumOfChildren(0); //Set amount of children in vuex
        this.form.numOfChildren = 0;
      } else {
        this.setNumOfChildren(value); //Set amount of children in vuex
        this.form.numOfChildren = value;
      }

      this.form.childrenAgeDropdowns = [
        ...Array(this.form.numOfChildren).keys(),
      ].map((index) => {
        return this.form.childrenAgeDropdowns[index] || {};
      });
    },
    setAmountOfAdults(value) {
      this.form.numOfAdults = value;
      let select = this.$refs.groupType.$el.childNodes[1].lastChild; //Get group type select dropdown
      let currentGroupType = select.options[select.selectedIndex].value;
      this.setNumOfAdults(value);
      if (this.numOfAdults > 2 && currentGroupType === "Couple") {
        this.toggleChildrenInputSpinner(true);
      }else{
        this.toggleChildrenInputSpinner(true);
      }
      this.$v.form.numOfAdults.$touch();
    },
    goToPreviousPage() {
      this.decreasePage();
      this.routerPush("/request/trip-details");
    },
    submit() {
      this.$v.form.$touch();
      if (!this.$v.form.$invalid) {

      if (!this.numOfChildren) {
        this.setNumOfChildren(0);
        this.setChildrenAgeDropdown([]);
      }

      this.$store.commit("request/SET_TRAVELER_DETAILS", this.form);
      this.increasePage();
      //Else skip it and go to the next page
      if (this.isExperience === false) {
        this.setIsInterestPage(true);
        this.setIsExperiencesPage(true);
        this.setFormData(this.form);
        this.routerPush("/request/interest");
      } else {
        this.setIsExperiencesPage(this.isExperience);
        this.setFormData(this.form);
        this.routerPush("/request/experiences");
      }
      } 
    },
    routerPush(path) {
      this.$router.push({
        path: path,
        query: {
          q: this.$route.query.q,
          country: this.$route.query.country,
          lang: this.$route.query.lang,
        },
      });
    }
  },
  created() {
    this.validateURLParams();
  },
  beforeMount() {
    if (this.isTravelersDetailsPage !== true) {
      this.routerPush("/request/trip-details");
    }
  },
  mounted() {
    this.form.childrenAgeDropdowns = this.childrenAgeDropdowns;
    this.form.numOfChildren = this.numOfChildren;
    this.form.numOfAdults = this.numOfAdults;
    this.form.groupType = this.groupType;
    this.form.budgetCurrency = this.budgetCurrency;
    this.form.budgetValue = this.budget;

    //Get group type dropdown value on page refresh or when landing on page
    let groupTypeSelect = this.$refs.groupType.$el.childNodes[1];
    let currentGroupType = this.groupType;
    if (currentGroupType) groupTypeSelect.value = currentGroupType;
  },
};
</script>
<style scoped>
.label-child {
  @apply text-black-base font-sans font-semibold text-sm mb-2 inline-block;
}

.select-field {
  @apply rounded-lg border focus:border-teal-base focus:ring-teal-lighter focus:ring-2 py-2.5 w-full p-2;
}

.navbar {
  @apply top-0 flex flex-wrap items-center w-full h-20 px-4 bg-white md:px-12;
}

.navbar-wrapper {
  @apply relative flex items-center justify-between w-full h-12 max-w-screen-md transform -translate-x-1/2 left-1/2;
}

.back-btn {
  @apply flex items-center p-3 text-xl duration-300 rounded text-black-lightest;
  @apply focus:outline-none focus:ring-2 focus:ring-black-lighter focus:scale-95 focus:ring-offset-2;
  @apply hover:text-black-base transition ease-in-out;
}

.back-btn-text {
  @apply pl-3 text-sm font-semibold uppercase;
}

.form {
  @apply w-full px-4 pb-48 mt-10 md:px-12;
}

.form-page {
  @apply relative max-w-screen-sm transform -translate-x-1/2 left-1/2;
}

.form-page-title {
  @apply mb-12 font-sans text-xl font-bold sm:text-2xl text-black-base;
}

.form-page-sub-title {
  @apply mb-16 font-sans text-lg text-black-lightest text-center sm:text-xl;
}

.field-container {
  @apply flex flex-wrap justify-between w-full items-start;
}

.label {
  @apply mb-2 font-sans text-base font-semibold w-full;
}

.bottom-nav {
  @apply fixed bottom-0 left-0 w-full p-4 bg-white border-t border-grey-base sm:mt-16;
  @apply block sm:hidden;
}

.bottom-nav-wrapper {
  @apply relative flex items-center justify-between max-w-screen-sm transform -translate-x-1/2 left-1/2;
}

.desktop-cta {
  @apply hidden sm:block h-12 px-8 w-full mt-8;
}
</style>
