<template>
  <div class="number-input-container">
    <div class="number-input-btn-wrapper">
      <label :for="name" class="number-input-label">
        {{ label }}
      </label>
      <div class="btn-container">
        <button
            :class="['decrement-btn number-input-btn', btnOpacity]"
            type="button"
            @click="decrementValue(); sendDecrementValue()"
        >
          <i class="bx bx-minus"></i>
        </button>
        <input
            :id="name"
            ref="input_value"
            :value="value"
            class="number-input"
            type="number"
            @blur="$emit('blur')"
            @keyup="emitMethods(); setValue($event.target.value)"
        />
        <button
            class="increment-btn number-input-btn"
            type="button"
            @click="incrementValue(); sendIncrementValue()"
        >
          <i class="bx bx-plus"></i>
        </button>
      </div>
    </div>
    <div class="number-input-wrapper">
      <span v-if="showMsg" class="error-msg">{{ errorMsg }}</span>
      <span v-if="help !== undefined" class="help-msg">{{ help }}</span>
    </div>
  </div>
</template>
<script>
export default {
  name: "NumberInputSpinner",
  data() {
    return {
      inputValue: this.value,
      btnOpacity: "opacity-100",
    };
  },
  props: {
    value: {
      type: Number,
      default: 0,
    },
    label: {
      type: String,
      default: "Label",
    },
    help: {
      type: String,
    },
    errorMsg: {
      type: String,
      default: "A value is required",
    },
    showMsg: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: "input",
    },
    placeholder: {
      type: String,
      default: "Input name",
    },
  },
  watch: {
    inputValue: function (value) {
      value >= 1
          ? (this.btnOpacity = "opacity-100")
          : (this.btnOpacity = "opacity-50");
    },
  },
  methods: {
    setValue(value) {
      this.inputValue = value;
    },
    emitMethods() {
      let ref = this.$refs.input_value.value;
      ref = parseInt(ref);
      this.$emit("keyup", ref);
    },
    decrementValue() {
      if (this.inputValue >= 1) {
        this.inputValue--;
      }
    },
    sendDecrementValue() {
      let ref = this.$refs.input_value.value;
      let value = parseInt(ref) - 1;

      this.$emit("getValue", value);
    },
    sendIncrementValue() {
      let ref = this.$refs.input_value.value;
      let value = parseInt(ref) + 1;
      this.$emit("getValue", value);
    },
    incrementValue() {
      this.inputValue++;
    },
  },
};
</script>
<style scoped>
.number-input {
  @apply leading-tight text-center w-16;
  @apply rounded py-3 border-transparent border;
  @apply focus:ring-transparent focus:ring-2 text-black-base;
  @apply placeholder-black-lightest;
}

/* Chrome, Safari, Edge, Opera */
.number-input::-webkit-outer-spin-button,
.number-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.number-input[type=number] {
  -moz-appearance: textfield;
}

.number-input-container {
  @apply flex flex-wrap border-none p-0;
}

.number-input-label {
  @apply font-sans font-semibold text-base text-black-base;
}

.number-input-btn-wrapper {
  @apply flex w-full justify-between items-center py-4;
}

.btn-container {
  @apply flex justify-between;
}

.number-input-btn {
  @apply text-black-light text-xl;
  @apply rounded-full flex justify-center items-center;
  @apply h-11 min-w-11;
  @apply border border-grey-base shadow-sm;
}

.error-msg {
  @apply font-sans text-sm mt-2 text-red-base;
}

.help-msg {
  @apply font-sans text-xs mt-2 text-black-light;
}
</style>
